<template>
  <div class="container">
    <van-form @submit="onSubmit">
      <van-cell-group>
        <!-- 通过 pattern 进行正则校验 -->
        <van-field v-model="ruleForm.realName" name="姓名" maxlength="20" placeholder="请输入真实姓名" />
        <!-- 通过 validator 进行函数校验 -->
        <van-field v-model="ruleForm.phoneNum" type="phoneNum" name="手机号" maxlength="11" placeholder="请输入手机号" />
        <!-- 通过 validator 返回错误提示 -->
        <van-field v-model="ruleForm.idCard" name="身份证" maxlength="18" placeholder="请输入身份证号" />
      </van-cell-group>
      <div style="margin: 50px 16px 20px">
        <van-button size="normal" round color="#f49b0d" block native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <div class="agreement">
      <van-checkbox
      shape="square"
      checked-color="#f49b0d"
      icon-size="16px"
      v-model="checked"
      >
        <p class="file">
          我已阅读并同意<span @click="userAgreement">《用户协议》</span>、<span @click="privacyPolicy">《隐私政策》</span>
        </p>
      </van-checkbox>
    </div>
  </div>
</template>

<script>
import { authentication } from "../api/index";
import { isPhoneReg,isIDCardReg } from '../utils/validate'
var showLoading = null; //定义toast的变量
export default {
  name: "IdentityAuthentication",
  data() {
    return {
      ruleForm: {
        projectId: this.$route.query.id, //项目组ID
        itemId: this.$route.query.itemId, //立达项目ID
        faceBase: "", //人脸图片 base64编码
        realName: "", //姓名
        phoneNum: "", //手机号
        idCard: "", //身份证
      },
      checked: false,
    };
  },
  created() {
    this.$bus.$on('faceBaseData',(data) => {
      this.ruleForm.faceBase = data.faceBase;
    });
  },
  destroyed(){
    this.$bus.$off('faceBaseData');
  },
  methods: {
    //用户协议
    userAgreement(){
      this.$router.push({
        path:'/agreement/userAgreement'
      })
    },
    //隐私政策
    privacyPolicy(){
      this.$router.push({
        path:'/agreement/privacyPolicy'
      })
    },
    //提交用户信息
    onSubmit() {
      let that = this;
      if(!this.checked){
        this.$toast(`请您先阅读用户协议和隐私政策`);
        return;
      }
      if(this.ruleForm.realName == ''){
        this.$toast(`请先输入真实姓名`);
        return;
      }
      if(this.ruleForm.phoneNum == ''){
        this.$toast(`请先输入手机号码`);
        return;
      }
      if(!isPhoneReg(this.ruleForm.phoneNum)){
        this.$toast(`手机号码格式不正确`);
        return;
      }
      if(this.ruleForm.idCard == ''){
        this.$toast(`请先输入身份证号`);
        return;
      }
      if(!isIDCardReg(this.ruleForm.idCard)){
        this.$toast(`身份证号格式不正确`);
        return;
      }
      showLoading = this.$toast({
        type: "loading",
        message: "正在提交，请稍后...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        loadingType: "spinner"
      });
      let location = JSON.parse(sessionStorage.getItem("location"))
      authentication({...this.ruleForm,...location}).then(() => {
        showLoading.close(); //请求结束关闭加载
        //200成功
        this.$router.push({
          path:'/success'
        })
      }).catch(err => {
        showLoading.close(); //请求结束关闭加载
        if(err.code == 500){
          //  接口异常抛出错误
          this.$toast(`${err.msg}`);
        }else if(err.code == 201){
          // 人脸注册失败重新人脸识别
          this.$toast(`${err.msg}，即将返回请稍后...`);
          setTimeout(function(){
            that.$router.go(-1)
          },1000)
        }else if(err.code == 202){
          // 身份信息正确重新进行人脸识别
          this.$toast(`${err.msg}，即将返回请稍后...`);
          setTimeout(function(){
            that.$router.go(-1)
          },1000)
        }
        
      })
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 100px 0 20px;
  .agreement{
    padding: 0 15px;
  }
  .file {
    font-size: 14px;
    line-height: 14px;
    color: #666 !important;
    span{
      color: #f26e21;
      text-decoration: underline;
    }
  }
}
</style>
