import request from "@/utils/request";

//查询项目状态
export function getItem(params) {
  return request({
    url: "/engineer/h5/getItem",
    method: "get",
    params: params,
  });
}

//h5人脸识别
export function identifyFace(data) {
  return request({
    url: "/engineer/h5/identifyFace",
    method: "post",
    data: data,
  });
}

// 实名认证
export function authentication(data) {
  return request({
    url: "/engineer/h5/authentication",
    method: "post",
    data: data,
  });
}
