/**
 * 手机号正则
 * @param {string} data
 * @returns {Boolean}
 */
export function isPhoneReg(data) {
  return /^1[3456789]\d{9}$/.test(data)
}
/**
 * 身份证号正则
 * @param {string} data
 * @returns {Boolean}
 */
export function isIDCardReg(data) {
  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(data)
}


